<template>
  <div class="btn-action d-flex flex-column justify-content-start align-items-center text-decoration-none px-3"
       :class="[buttonClass, buttonVariant, { 'text-muted': disabled }, (disabled) ? disabledCursorType : '']"
       v-shortkey="shortkey"
       active-class="active"
       role="button"
       @click="trackClick($event)"
       @shortkey="shortKeyEvent()" >
    <fluency-icon :type="icon"
                  :size="iconSize"
                  :class="[iconClass, { 'text-muted': disabled }]" />
    <div class="small pt-2 white-space-no-wrap">
      {{ buttonText }}
      <fluency-icon v-if="caret"
                    type="caretDown"
                    size="xs"
                    :class="{ 'text-muted': disabled }" />
    </div>

    <span v-if="notificationCount > 0" class="badge badge-pill badge-info notification-badge" :class="notificationClass">{{notificationCount}}</span>
  </div>
</template>

<script>

export default {
  name: 'actionIcon',
  components: {
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    iconClass: {
      type: [Array, Object, String]
    },
    buttonText: {
      type: String,
      required: true
    },
    variant: {
      type: String
    },
    notificationCount: {
      type: Number
    },
    notificationClass: {
      type: [Array]
    },
    shortkey: {
      type: [Array, Object]
    },
    buttonClass: {
      type: String
    },
    iconSize: {
      type: String,
      default: 'md'
    },
    caret: {
      type: Boolean,
      default: false
    },
    trackingParams: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledCursorType: {
      type: String,
      default: 'cursor-disabled'
    }
  },
  computed: {
    buttonVariant() {
      return this.variant ? `btn-${this.variant}` : ''
    }
  },
  methods: {
    shortKeyEvent () {
      if (this.disabled) {
        return false
      }

      this.$emit('shortkey')
    },
    trackClick (event) {
      if (this.disabled) {
        return false
      }

      this.$emit('click', event)

      const route = this.$route.path.includes('/manage/') ? 'manage' : ''

      this.$track.event('action icon button', {
        actionIcon: this.buttonText,
        path: this.$route.fullPath,
        ...this.trackingParams
      }, route, event.target.closest('.btn-action'))
    }
  }
}
</script>

<style lang='scss' scoped>
.btn-action {
  line-height: 1;
}

.btn .badge.notification-badge {
  position: absolute;
  left: calc(50% + 0.55rem); // 0.75rem = button padding
  top: 0;
}

</style>
